import type { FormItem, FormItemKinds } from '@/components/form-builder/types'
import { defineComponent, type Component } from 'vue'
import TextInputItem from './components/Items/TextInputItem.vue'
import BooleanInputItem from './components/Items/BooleanInputItem.vue'
import CountryInputItem from './components/Items/CountryInputItem.vue'
import ParagraphItem from './components/Items/ParagraphItem.vue'
import CapacityInputItem from './components/Items/CapacityInputItem.vue'
import EventGroupInputItem from './components/Items/EventGroupInputItem.vue'
import EventLodgeRoomInputItem from './components/Items/EventLodgeRoomInputItem.vue'
import SubEventInputItem from './components/Items/SubEventInputItem.vue'
import TransportLocationInputItem from './components/Items/TransportLocationInputItem.vue'
import DateInputItem from './components/Items/DateInputItem.vue'
import type { FormItemData } from './services/types'
import TimeInputItem from './components/Items/TimeInputItem.vue'
import LongTextInputItem from './components/Items/LongTextInputItem.vue'
import SelectInputItem from './components/Items/SelectInputItem.vue'
import ConsentInputItem from './components/Items/ConsentInputItem.vue'
import CCVInputItem from './components/Items/CCVInputItem.vue'
import CCNumberInputItem from './components/Items/CCNumberInputItem.vue'
import EventProgramItemInput from './components/Items/EventProgramItemInput.vue'
import PersonTitleInputItem from './components/Items/PersonTitleInputItem.vue'
import PhoneInputItem from './components/Items/PhoneInputItem.vue'
import CCExpirationItem from './components/Items/CCExpirationItem.vue'
import FileUploadItem from './components/Items/FileUploadItem.vue'

const Blank = defineComponent({ render: () => null })

type ItemDefinition = {
  component: Component
  validate?: (data: FormItemData, item: FormItem) => string | undefined
}

const ITEMS: Record<FormItemKinds, ItemDefinition> = {
  // Personal
  first_name: { component: TextInputItem },
  last_name: { component: TextInputItem },
  email: {
    component: TextInputItem,
    validate: (data) => {
      if (typeof data !== 'string' || !data) return

      const emailsArray = data
        .replace(/\s/g, ',')
        .split(',')
        .filter((item) => item.length > 0)

      const regex = /^\S+@\S+$/

      if (emailsArray.length === 0 || !emailsArray.every((email) => regex.test(email))) {
        return 'Please enter a valid email or emails, separated by comma'
      }
    }
  },
  phone: {
    component: PhoneInputItem,
    validate: (data, item) => {
      if (!item.required) {
        return undefined
      }

      if (!data || typeof data !== 'object') {
        return 'Please enter a valid phone number'
      }

      const { code, number } = data as { code?: string; number?: string }

      if (!code || !number) {
        return 'Please enter a valid phone number'
      }
    }
  },
  country: { component: CountryInputItem },
  title: { component: PersonTitleInputItem },
  dietary_requirements: { component: TextInputItem },

  // Passport
  passport_number: { component: TextInputItem },
  passport_first_name: { component: TextInputItem },
  passport_middle_name: { component: TextInputItem },
  passport_last_name: { component: TextInputItem },
  passport_nationality: { component: CountryInputItem },
  passport_birth_day: { component: DateInputItem },
  passport_issued_date: { component: DateInputItem },
  passport_expiry_date: {
    component: DateInputItem,
    validate: (data) => {
      if (typeof data === 'string' && data && new Date(data) < new Date()) {
        return 'Passport has expired'
      }
    }
  },

  // Institution
  represented_institution: { component: TextInputItem },
  institutional_position: { component: TextInputItem },

  // Event
  capacity: { component: CapacityInputItem },
  event_group: { component: EventGroupInputItem },
  sub_event: { component: SubEventInputItem },
  transport_location: { component: TransportLocationInputItem },
  event_program: { component: EventProgramItemInput },

  // Accommodation
  lodge_and_room: { component: EventLodgeRoomInputItem },
  from_date: { component: DateInputItem },
  to_date: { component: DateInputItem },
  room_requirements: { component: TextInputItem },
  smoking_room: { component: BooleanInputItem },
  kosher_breakfast: { component: BooleanInputItem },
  cc_number: {
    component: CCNumberInputItem,
    validate: (data) => {
      if (typeof data !== 'string' || !data) return
      if (data.length < 15) return 'Please enter a valid credit card number'
    }
  },
  cc_owner: { component: TextInputItem },
  cc_expiry_date: {
    component: CCExpirationItem,
    validate: (data) => {
      if (typeof data !== 'string') return

      if (!/^\d\d\/\d\d$/.test(data)) {
        return 'Please enter a valid expiry date. Format: MM/YY'
      }

      const [month, year] = data.split('/').map((v) => parseInt(v, 10))

      if (month < 1 || month > 12) {
        return 'Please enter a valid expiry date. Month must be between 1 and 12'
      }

      if (new Date(2000 + year, month - 1, 28) < new Date()) {
        return 'Card has expired'
      }
    }
  },
  cc_security_code: {
    component: CCVInputItem,
    validate: (data) => {
      if (typeof data !== 'string' || !data) return
      if (data.length !== 3 && data.length !== 4) return 'Please enter a valid CCV'
    }
  },

  // Primitive
  paragraph: { component: ParagraphItem },
  consent: { component: ConsentInputItem },
  text: { component: TextInputItem },
  long_text: { component: LongTextInputItem },
  boolean: { component: BooleanInputItem },
  select: { component: SelectInputItem },
  date: { component: DateInputItem },
  time: { component: TimeInputItem },
  custom_country: { component: CountryInputItem },
  file: { component: FileUploadItem },
  unknown: { component: Blank }
}

export const getItemDefinition = (kind: FormItemKinds) =>
  typeof ITEMS[kind] !== 'undefined' ? ITEMS[kind] : ITEMS.unknown

export const getItemComponent = (kind: FormItemKinds) => getItemDefinition(kind).component
