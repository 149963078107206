/* eslint-disable */
// ⚠️⚠️⚠️⚠️⚠️
// This file was automatically generated and should not be edited.
// ⚠️⚠️⚠️⚠️⚠️

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: any; output: any; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: any; output: any; }
  /** Represents untyped JSON */
  JSON: { input: any; output: any; }
};

export type Capacity = {
  __typename?: 'Capacity';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CollectionExport = {
  __typename?: 'CollectionExport';
  url?: Maybe<Scalars['String']['output']>;
};

export enum CollectionExportFormat {
  Csv = 'CSV',
  Xlsx = 'XLSX'
}

export type CollectionMetadata = {
  __typename?: 'CollectionMetadata';
  /** Items per page */
  items: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  series: Array<Scalars['String']['output']>;
  total: Scalars['Int']['output'];
};

export type CountriesFilters = {
  havingCallingCodes?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CountriesSort = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<CountriesSortFieldsType>;
};

export enum CountriesSortFieldsType {
  Name = 'NAME'
}

export type Country = {
  __typename?: 'Country';
  callingCodes: Array<Scalars['String']['output']>;
  /** ISO 2 letter code */
  code: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type CountryCollection = {
  __typename?: 'CountryCollection';
  collection: Array<Country>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type CountryCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Base type for all mutations errors */
export type Error = {
  __typename?: 'Error';
  messages: Array<Scalars['String']['output']>;
  path: Scalars['String']['output'];
};

export type Event = {
  __typename?: 'Event';
  capacities: Array<EventCapacity>;
  endDate?: Maybe<Scalars['ISO8601Date']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  groups: Array<EventGroup>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  location?: Maybe<Scalars['String']['output']>;
  lodges: Array<Lodge>;
  name: Scalars['String']['output'];
  startDate?: Maybe<Scalars['ISO8601Date']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
  subEvents: Array<SubEvent>;
  transportLocations: Array<EventTransportLocation>;
};

export type EventCapacity = {
  __typename?: 'EventCapacity';
  capacity: Capacity;
  id: Scalars['ID']['output'];
};

export type EventGroup = {
  __typename?: 'EventGroup';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: EventGroupType;
};

export type EventGroupType = {
  __typename?: 'EventGroupType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type EventTransportLocation = {
  __typename?: 'EventTransportLocation';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Form = {
  __typename?: 'Form';
  backgroundColor: Scalars['String']['output'];
  closedMessageBody: Scalars['String']['output'];
  event: Event;
  header?: Maybe<ImageBlob>;
  id: Scalars['ID']['output'];
  isArchived: Scalars['Boolean']['output'];
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaTitle?: Maybe<Scalars['String']['output']>;
  plainTitle: Scalars['String']['output'];
  status: FormStatusEnum;
  submitButtonLabel: Scalars['String']['output'];
  successMessageBody: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type FormSession = {
  __typename?: 'FormSession';
  createdAt: Scalars['ISO8601DateTime']['output'];
  data: Scalars['JSON']['output'];
  formRows: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  parsedData: Array<FormSubmissionParsedDataItem>;
  preview: Scalars['Boolean']['output'];
  submittedEmail?: Maybe<Scalars['String']['output']>;
  submittedFirstName?: Maybe<Scalars['String']['output']>;
  submittedLastName?: Maybe<Scalars['String']['output']>;
};

export enum FormStatusEnum {
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export type FormSubmissionParsedDataItem = {
  __typename?: 'FormSubmissionParsedDataItem';
  displayValue?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  kind: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type ImageBlob = {
  __typename?: 'ImageBlob';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type Lodge = {
  __typename?: 'Lodge';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  roomQuantities: Array<LodgeRoomQuantity>;
  visibleInForms: Scalars['Boolean']['output'];
};

export type LodgeRoomQuantity = {
  __typename?: 'LodgeRoomQuantity';
  id: Scalars['ID']['output'];
  lodge: Lodge;
  roomType: RoomType;
  sharingAllowed: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  startFormSession?: Maybe<StartSessionMutationPayload>;
  submitFormSessionData?: Maybe<SubmitSessionDataMutationPayload>;
};


export type MutationStartFormSessionArgs = {
  input: StartSessionMutationInput;
};


export type MutationSubmitFormSessionDataArgs = {
  input: SubmitSessionDataMutationInput;
};

/** A type for sorting plural fields */
export type Pagination = {
  /** Items per page */
  items?: InputMaybe<Scalars['Int']['input']>;
  /** Page to fetch */
  page?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonTitle = {
  __typename?: 'PersonTitle';
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};

export type PersonTitleCollection = {
  __typename?: 'PersonTitleCollection';
  collection: Array<PersonTitle>;
  export: CollectionExport;
  meta: CollectionMetadata;
};


export type PersonTitleCollectionExportArgs = {
  columns?: InputMaybe<Array<Scalars['String']['input']>>;
  format?: InputMaybe<CollectionExportFormat>;
};

export type PersonTitleSort = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<PersonTitlesSortFields>;
};

export enum PersonTitlesSortFields {
  CreatedAt = 'CREATED_AT',
  Title = 'TITLE'
}

export type Query = {
  __typename?: 'Query';
  countries: CountryCollection;
  event?: Maybe<Event>;
  eventLodge?: Maybe<Lodge>;
  form?: Maybe<Form>;
  formSession?: Maybe<FormSession>;
  personTitles: PersonTitleCollection;
};


export type QueryCountriesArgs = {
  filters?: InputMaybe<CountriesFilters>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<CountriesSort>;
};


export type QueryEventArgs = {
  id: Scalars['ID']['input'];
};


export type QueryEventLodgeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFormArgs = {
  slug: Scalars['String']['input'];
};


export type QueryFormSessionArgs = {
  sessionId: Scalars['String']['input'];
};


export type QueryPersonTitlesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  pagination?: InputMaybe<Pagination>;
  search?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<PersonTitleSort>;
};

/** RoomType */
export type RoomType = {
  __typename?: 'RoomType';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** Autogenerated input type of StartSessionMutation */
export type StartSessionMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  slug: Scalars['String']['input'];
};

/** Autogenerated return type of StartSessionMutation. */
export type StartSessionMutationPayload = {
  __typename?: 'StartSessionMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  formSession?: Maybe<FormSession>;
  success: Scalars['Boolean']['output'];
};

export type SubEvent = {
  __typename?: 'SubEvent';
  batchResponse?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['ISO8601Date']['output']>;
  endTime?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  shortName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['ISO8601Date']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated input type of SubmitSessionDataMutation */
export type SubmitSessionDataMutationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  data: Scalars['JSON']['input'];
  lastStep?: InputMaybe<Scalars['Boolean']['input']>;
  sessionId: Scalars['ID']['input'];
};

/** Autogenerated return type of SubmitSessionDataMutation. */
export type SubmitSessionDataMutationPayload = {
  __typename?: 'SubmitSessionDataMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors?: Maybe<Array<Error>>;
  formSession?: Maybe<FormSession>;
  success: Scalars['Boolean']['output'];
};
